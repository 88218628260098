<template>
  <div class="modal fade" tabindex="-10" id="modal_add_utenza_abilitata">
    <div class="modal-dialog">
      <div class="modal-content">
        <loading
          :active="isLoading"
          :is-full-page="fullPage"
          :z-index="1100"
        ></loading>
        <div class="modal-header">
          <h5 class="modal-title">Crea utenza</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>
        <Form
          class="form"
          @submit="onSubmit"
          :validation-schema="schema"
          @invalid-submit="onInvalidSubmit"
        >
          <div class="modal-body">
            <div class="card-body p-6">
              <div class="mt-5 mb-8 bg-secondary py-5 px-4 rounded">
                <div class="blueFit fs-6">
                  Stai creando le <b>nuove credenziali </b> per accedere al
                  Management FITP. La <b> mail attuale </b> dell'utente è
                  <b>{{ old_mail }}</b> puoi decidere di mantenerla o di
                  modificarla
                </div>
              </div>
              <div class="mt-5 mb-8 bg-light-danger py-5 px-4 rounded">
                <div class="text-danger fs-6">
                  Inserire la <b>password</b> desiderata e
                  <b>memorizzarla</b> in modo, se necessario, da poterla
                  comunicare all'utente.
                  <br />
                  <small>
                    L'utente riceverà una email di riepilogo con indicati
                    password e indirizzo mail da utilizzare per l'accesso al
                    Management FITP. <br />
                    (In caso di smarrimento sarà possibile recuperare la
                    password tramite apposito pulsante presente nella pagina del
                    nuovo login).</small
                  >
                </div>
              </div>
              <div class="row pb-4 ps-3">
                <div class="col-md-4 mb-2">
                  <label class="fw-bold text-gray-800">E-mail*</label>
                  <Field
                    name="mail"
                    type="text"
                    class="form-control"
                    v-model="mail"
                  />
                  <ErrorMessage
                    class="help-message"
                    style="color: red"
                    name="mail"
                  />
                </div>
                <div class="col-md-4 mb-2">
                  <label class="fw-bold text-gray-800">Password*</label>
                  <Field
                    name="password"
                    type="text"
                    class="form-control"
                    v-model="password"
                  />
                  <ErrorMessage
                    class="help-message"
                    style="color: red"
                    name="password"
                  />
                </div>
                <div class="col-md-4 mb-2">
                  <label class="fw-bold text-gray-800"
                    >Conferma password*</label
                  >
                  <Field
                    name="confirm_password"
                    type="text"
                    class="form-control"
                    v-model="confirm_password"
                    :disabled="!password"
                  />
                  <ErrorMessage
                    class="help-message"
                    style="color: red"
                    name="confirm_password"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <span
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalUtenzaAbilitata"
            >
              Chiudi
            </span>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Crea utenza
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref } from "vue";

import { addUtenzaAbilitata } from "@/requests/utenzeRequests";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { trimInput } from "@/composables/trimInput";
import { Form, ErrorMessage, Field } from "vee-validate";
import * as yup from "yup";
import YupPassword from "yup-password";
YupPassword(yup);

export default {
  name: "add-utente-albo",
  components: { Loading, Form, ErrorMessage, Field },
  emits: ["refreshList"],
  props: {
    utente: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isLoading = ref(false);

    const old_mail = ref(null);

    const schema = yup.object().shape({
      mail: yup.string().required("Obbligatorio").nullable(),
      password: yup
        .string()
        .required("Obbligatorio")
        .minLowercase(
          1,
          "La password deve contenere almeno una lettera minuscola"
        )
        .minUppercase(
          1,
          "La password deve contenere almeno una lettera maiuscola"
        )
        .minNumbers(1, "La password deve contenere almeno un numero")
        .minSymbols(
          1,
          "La password deve contenere almeno un carattere speciale"
        )
        .min(8, "La password deve avere un minimo di 8 caratteri")
        .nullable(),
      confirm_password: yup
        .string()
        .oneOf([yup.ref("password"), null], "Le password devono coincidere")
        .nullable(),
    });

    watch(
      () => props.utente,
      (value) => {
        if (value) {
          old_mail.value = props.utente.mail;
          resetField();
        }
      }
    );

    const onSubmit = () => {
      Swal.fire({
        title: "Sei Sicuro?",
        html: "Procedendo verrà creata l'utenza.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Crea utenza",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await addUtenzaAbilitata(
            props.utente.username,
            trimInput(password.value),
            trimInput(mail.value),
            props.utente.id_comitato,
            props.utente.id_societa,
            props.utente.id_persona,
            props.utente.id_omologatore,
            props.utente.id_utente
          ).then((res) => {
            if (res.status == 200) {
              alertSuccess(
                res.data.message
                  ? res.data.message
                  : "Utenza creata correttamente"
              );
              emit("refreshList");
              document.getElementById("closeModalUtenzaAbilitata").click();
              resetField();
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const mail = ref(null);
    const password = ref(null);
    const confirm_password = ref(null);

    const resetField = () => {
      mail.value = null;
      password.value = null;
      confirm_password.value = null;
    };

    return {
      old_mail,
      mail,
      confirm_password,
      password,
      isLoading,
      schema,
      onSubmit,
    };
  },
};
</script>

<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
